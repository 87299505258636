@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

@layer utilities {
	.container {
		width: 100%;
		height: 100%;
		padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
    margin-right: auto;
    margin-left: auto;
	}
  .divider {
    @apply border border-solid border-gray-400 my-2;
  }
	.card {
		width: 100%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 7px;
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
    z-index: 9;
    padding: 40px 30px;
    padding-bottom: 30px;
    -webkit-box-shadow: 0px 10px 60px 0px rgba(200, 226, 255, 0.45);
    box-shadow: 0px 10px 60px 0px rgba(200, 226, 255, 0.45);
	}
	.hero {
		@apply bg-cover;
		padding: 230px 0px 225px;
    z-index: 1;
    position: relative;
    overflow: hidden;
	}
	.hero::before {
		@apply absolute top-0 left-0 w-full h-full bg-no-repeat;
    /* background-image: url(/images/slide_bg_circle.png); */
    content: "";
    z-index: -1;
    opacity: 0.5;
	}
	.text-primary {
    --text-opacity: 1;
    color: #211e3b;
  }
  .text-100 {
    font-size: 100px;
  }
  .text-80 {
    font-size: 80px;
  }
  .leading-80 {
    line-height: 80%;
  }
  .theme-btn {
    @apply flex justify-center;
  	color: #FFF;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #086ad7;
    border: 1px solid #086ad7;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 1;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    text-transform: uppercase;
  }
  .minimal-btn {
  	color: #211e3b;
    border: 1px solid #b0cff2;
    background: transparent;
    -webkit-box-shadow: inherit;
    box-shadow: inherit;
  }
  .nav-item {
    @apply bg-gray-200 w-full pt-2 pb-2 pl-2 hover:bg-gray-400 active:bg-gray-300
  }
  .box-shadow {
    box-shadow: 0px 10px 60px 0px rgba(200, 226, 255, 0.45);
  }
}